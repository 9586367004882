<template>
  <div class="contenedor-indicador">
    <Spinner 
      :mostrarSpinner="spinner"
    />

    <!-- Navegqación -> Titulo y boton <- -->
    <div class="indicador-head" v-if="mostrarBoton">
      <div class="indicador-body">
        <h1>Universo de aspirantes</h1>
      </div>
    </div>


    <div class="agregar-indicador" v-if="formularioDatos">
      <!-- contenedores de información "Formulario" -->
        <div class="contenedor-formulario">
          <form @submit.prevent="">


          <div class="input-label">
             <label>Mujeres aspirantes:</label>
              <input 
                type="String" 
                v-model="contenedorData.mujeres"
                class="form-control-my m-2"
              >
          </div>

          <div class="input-label">
             <label>Hombres aspirantes:</label>
              <input 
              type="String" 
              v-model="contenedorData.hombres"
              class="form-control-my m-2"
              >
          </div>

          <div class="input-label">
             <label>Total aspirantes:</label>
              <input 
              type="String" 
              v-model="contenedorData.totales"
              class="form-control-my m-2"
              >
          </div>
         
          <div class="input-label">
             <label>Periodo:</label>
              <input 
              type="option" 
              v-model="contenedorData.periodo"
              class="form-control-my m-2"
              >
          </div>

           <div class="input-label-large">
             <label>Partido Político:</label>
              <input 
              type="String" 
              v-model="contenedorData.partido"
              class="form-control-my m-2"
              >
          </div>
          <div class="input-label-large">
              <label>Distrito:</label>
              <select name="distritos" id="distritos" v-model="contenedorData.distrito">
                <option value="">Selecciona un distrito</option>
                <option value="Todos">Todos los distritos</option>
                <option value="distrito-1">Distrito 1</option>
                <option value="distrito-2">Distrito 2</option>
                <option value="distrito-3">Distrito 3</option>
                <option value="distrito-4">Distrito 4</option>
                <option value="distrito-5">Distrito 5</option>
                <option value="distrito-6">Distrito 6</option>
                <option value="distrito-7">Distrito 7</option>
                <option value="distrito-8">Distrito 8</option>
                <option value="distrito-9">Distrito 9</option>
                <option value="distrito-10">Distrito 10</option>
                <option value="distrito-11">Distrito 11</option>
                <option value="distrito-12">Distrito 12</option>
              </select>
              
          </div>

            <div class="botones-accion">
              <button class="btn btn-danger m-3" @click="limpiarForm" >Limpiar</button>
              <button class="btn btn-primary" @click="addRegistro(contenedorData)">Agregar</button>
            </div>
              
          </form>
        </div>

      <!-- contenedores de información "Data Preview" -->
        
        <div class="mensaje-registro" v-if="mensajeContenedor">
          {{ mensajeConfirmacion }}
        </div>      
    </div>

  

    <div class="datos-informacion"  v-if="fichaDatos">
      <div class="ficha-datos">
        <h1>Información capturada</h1>
        <p @click="regresar">Regresar</p>
      </div>
      <button class="btn-boton-consultar" @click="mostrarInformacion">Consultar</button>
      <div class="mostrar-ficha-informacion" v-if="viewInformacion">
         <div class="ficha-contenedor-datos" v-for="(item, index) in indicadores" :key="index">
        
              <div class="view-datos">
                <p>Periodo:  {{ item.periodo }} </p>
                <p>Distrito: {{ item.distrito }} </p>
              </div>
              <div class="view-datos">
                <p>Hombre: {{ item.hombres }} </p>
                <p>Mujeres: {{ item.mujeres }} </p>
              </div>
              <div class="view-datos">
                <button @click="eliminarRegistro(item._id)">Eliminar</button>
            </div>
        </div>
      </div>
    </div>



    
     
  </div>

</template>
<script>


import { defineAsyncComponent } from 'vue'

export default {

  components: {
    BotonAdd: defineAsyncComponent( () => import('../../../components/BotonAdd.vue')),
    Spinner: defineAsyncComponent( () => import('../../../components/Spinner.vue'))
  },
  data() {
    return {
      indicadorData: false,
      indicadorFormulario: false,
      datosIndicador: false,
      diputaciones: {
        isLoading: true,
      },
      contenedorData:{
        hombres: null,
        mujeres: null,
        totales: null,
        distrito: null,
        partido: null,
        periodo: null,
        usuarioId: 'Administrador-01'
      },
      spinner: true,
      mostrarBoton: false,
      formularioDatos: false,
      fichaDatos: false,
      mensajeConfirmacion: null,
      mensajeContenedor: false,
      viewInformacion: false,
      indicadores: []
    }
  },
  methods: {
    mostrarMenu(){
      
    },
    stopSpinner(){

      setTimeout(() => {
        this.spinner = false
        this.mostrarBoton = true 
        this.indicadorFormulario = true
        this.formularioDatos = true
        this.fichaDatos = false
      },3000);
    },
    cerrarRegistro(){
      this.indicadorFormulario = false
      this.indicadorData = true
    },
    addRegistro( data ){
      const url = '/diputaciones/proceso-electoral/nuevo/universo-aspirantes'
      this.axios.post(`${ url }`, data )
        .then( res => {
          this.mensajeContenedor = true
          this.mensajeConfirmacion = 'Información agregada correctamente'
          this.limpiarForm()
          setTimeout(() => {
              this.mensajeContenedor = false
              this.mensajeConfirmacion = null
              this.formularioDatos = false
              this.fichaDatos = true
          }, 2000);
          
        })
        .catch( e => {
          console.log( e );
        })

    },
    limpiarForm() {
        this.contenedorData.hombres = ''
        this.contenedorData.mujeres = ''
        this.contenedorData.totales = ''
        this.contenedorData.distrito = ''
        this.contenedorData.periodo = ''
        this.contenedorData.partido = ''
    },
    regresar() {
      this.fichaDatos = false
      this.formularioDatos = true
    },
    mostrarInformacion() {
      this.viewInformacion = true
       const url = '/diputaciones/proceso-electoral/nuevo/universo-aspirantes'
           this.axios.get(`${url}`)
            .then( res => {
                return this.saveData( res.data )
            })
            .catch( e => {
                console.log( 'error: ' + e );
            }) 
    },
    saveData( data ) {
      this.indicadores = data 
    },
    eliminarRegistro( data ) {
      console.log( data );
    }
  },
  created(){
    this.stopSpinner()
  }

}
</script>

<style lang="scss">
  .ficha-data{
    width:80%;
    margin:20px auto;
    height:200px;
    background-color: #f1f1f1;
    .header-data{
      padding: 12px 12px;
      display:flex; align-items:center; justify-content: space-between;
    }
    .body-data{
      display: flex;  
    }
  }

  .agregar-indicador{
    width:90%;
    height: calc(100vh - 220px);
    margin:10px auto;
    padding:14px 12px;
    text-align:center;
    display: flex;
    flex-direction: column; 
    & p{
      text-align:right;
      margin-right:40px;
      font-size:20px;
      font-weight: bold;
      cursor:pointer;

    }
    form{

      width:90%;
      margin:10px auto;
      display: flex;
      flex-direction: column;

      .input-label{
          display:flex;
          label{
            width:700px;
            text-align:left;
            // background-color: red;
          }
          input { 
            width: 80px;
            height:50px;
            text-align:center;
          } 
      }

      .input-label-large{
          display: flex;
          label{
            width:500px;
            text-align:left;
            // background-color: red;
          }

          input {
                width:280px; 
                height: 30px;
            }
            
          select {
                width:280px; 
                height: 30px;
                margin:20px auto;
          }
   
        }

        button {
          width:200px;
          margin:20px auto;
        }
      
      
    }

    .contenedor-formulario {
      width:90%;
      margin:10px auto;
    }
    .contenedor-informacion {
      width:40%;
      margin:10px auto;
      background-color: #f1f1f1;
      overflow-y: auto;

      .ficha-info{
        width:93%;
        height:100px;
        margin:20px auto;
        background-color: #fff;
        border-radius:10px;

        .info-data{
          display:flex; align-items:center; justify-content:center;
          width:100%;
          height:100px;
          & p {
          font-size: 12px;          
          }

        }
        
      }
    }

    .mensaje-registro {
      width:80%;
      height:50px;
      margin:10px auto;
      font-size:16px;
      font-weight: bold;
      background-color: #f1f1f1;
      display: flex; align-items:center; justify-content:center;
    }

    
  }

  .datos-informacion{
    width:80%;
    margin:20px auto;
    height: calc(100vh - 240px);
    background-color: #f1f1f1;
    overflow-y:auto;

     button{
        width:100px;
        height:30px;
        font-size:12px;
      }
    .btn-boton-consultar{
      float: right;
      margin-right:20px;
    }
    .ficha-datos {
      display: flex; 
      justify-content: space-between;
      padding:16px 18px;
      & h1{
        font-size:18px;
      }
      & p {
        font-size:12px;
        font-weight: bold;
        cursor:pointer;
      }
     
    }
    .ficha-contenedor-datos{
      width:96%;
      height:150px;
      background-color: #fff;
      margin:10px auto;
      border-radius:10px;
      display:flex; align-items:center; justify-content:center;

      .view-datos{
        width:33%;
        display: flex; flex-direction: column;
        align-items:center;

        button{
          width:100px;
          height:30px;
          font-size:12px;
        }
      }
    }
  }

  
  
</style>